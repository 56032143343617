.container {
    background-color: transparent;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 678px;
    max-width: 100%;
    min-height: 400px;
  }
  
  .signup-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }

  .signin-container{
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    left: 0;
    width: 50%;
    z-index: 2;
  }
  
  
  .form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
    padding: 20px 20px;

  }
  
  .title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
  }

  .eye-btn-signIn{
    position:sticky;
    left: 41%;
    bottom: 38%;
    font-size: 24px;
  }

  .eye-btn-signUp{
    position:sticky;
    left: 41%;
    bottom: 30%;
    font-size: 24px;
  }
  
  .buttonSubmit {
    border-radius: 20px;
    border: 1px solid  #D291BC;
    background-color:  #D291BC;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 160ms ease-in;
  }
  
  .buttonSubmit:active {
    transform: scale(0.95);
  }
  
  .buttonSubmit:focus {
    outline: none;
  }
  
  .ghost-button {
    border-radius: 20px;
    border: 1px solid  #ffffff;
    background-color: transparent;
    border-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
  }
  
  .anchor {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
  }
  
  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.8s ease-in-out;
    z-index: 100;
  }
  
  
  .overlay {
    background:  #D291BC;
    background: -webkit-linear-gradient(to right, #957DAD, #D291BC);
    background: linear-gradient(to right, #957DAD, #D291BC);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .overlay-panel.left {
    transform: translateX(-20%);
    padding: 20px 20px;

  }
  
  
  .overlay-panel.right {
    right: 0;
    transform: translateX(0);
    padding: 20px 20px;

  }
  
  .paragraph {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }
  


/* LOADING */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
}

.loader {
  --c1:#673b14;
  --c2:#f8b13b;
  width: 40px;
  height: 80px;
  border-top: 4px solid var(--c1);
  border-bottom: 4px solid var(--c1);
  background: linear-gradient(90deg, var(--c1) 2px, var(--c2) 0 5px,var(--c1) 0) 50%/7px 8px no-repeat;
  display: grid;
  overflow: hidden;
  animation: l5-0 2s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  width: 75%;
  height: calc(50% - 4px);
  margin: 0 auto;
  border: 2px solid var(--c1);
  border-top: 0;
  box-sizing: content-box;
  border-radius: 0 0 40% 40%;
  -webkit-mask: 
    linear-gradient(#000 0 0) bottom/4px 2px no-repeat,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: destination-out;
          mask-composite: exclude;
  background: 
    linear-gradient(var(--d,0deg),var(--c2) 50%,#0000 0) bottom /100% 205%,
    linear-gradient(var(--c2) 0 0) center/0 100%;
  background-repeat: no-repeat;
  animation: inherit;
  animation-name: l5-1;
}
.loader::after {
  transform-origin: 50% calc(100% + 2px);
  transform: scaleY(-1);
  --s:3px;
  --d:180deg;
}
@keyframes l5-0 {
  80%  {transform: rotate(0)}
  100% {transform: rotate(0.5turn)}
}
@keyframes l5-1 {
  10%,70%  {background-size:100% 205%,var(--s,0) 100%}
  70%,100% {background-position: top,center}
}
